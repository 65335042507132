<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import AuthenticationCard from '@/Components/AuthenticationCard.vue';
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';

defineProps({
    canResetPassword: Boolean,
    status: {
      type: String,
      required: true
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
  <div id="login-wrapper">
    <Head title="Log in" />

    <AuthenticationCard>
      <template #logo>
        <h1>Jouw sieraden op srdn.nl</h1>
      </template>

      <div
        v-if="status"
        class="mb-4 text-sm font-medium text-green-600"
      >
        {{ status }}
      </div>

      <form @submit.prevent="submit">
        <div>
          <InputLabel
            for="email"
            value="Email"
          />
          <TextInput
            id="email"
            v-model="form.email"
            type="email"
            class="block w-full mt-1"
            required
            autofocus
            autocomplete="username"
          />
          <InputError
            class="mt-2"
            :message="form.errors.email"
          />
        </div>

        <div class="mt-4">
          <InputLabel
            for="password"
            value="Wachtwoord"
          />
          <TextInput
            id="password"
            v-model="form.password"
            type="password"
            class="block w-full mt-1"
            required
            autocomplete="current-password"
          />
          <InputError
            class="mt-2"
            :message="form.errors.password"
          />
        </div>

        <div class="block mt-4">
          <label class="flex items-center">
            <Checkbox
              v-model:checked="form.remember"
              name="remember"
            />
            <span class="ml-2 text-sm text-gray-800">Onthoud mij</span>
          </label>
        </div>

        <div class="flex items-center justify-end mt-4">
          <Link
            v-if="canResetPassword"
            :href="route('password.request')"
            class="text-sm text-gray-800 underline rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Wachtwoord vergeten?
          </Link>

          <PrimaryButton
            class="ml-4"
            :class="{ 'opacity-25': form.processing }"
            :disabled="form.processing"
          >
            Inloggen
          </PrimaryButton>
        </div>
      </form>
    </AuthenticationCard>
  </div>
</template>
<style scoped>
  #login-wrapper {
    background-image: url('/img/login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>